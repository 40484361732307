import React from "react";
import Photos from "./Photos";
import Videos from "./Videos";
import Nav from "./Nav";
import SimpleReactLightbox from "simple-react-lightbox";
import "./Home.css";

export default function Home() {
  return (
    <div>
      <div className="row header">
        <Nav />
        <img
          className="header-image"
          src={require(`../src/images/LifeIsAJourney4.jpg`)}
          alt="life"
        />
      </div>
      <div className="title-bio">Hi! I'm Mariann Montoya Biondi</div>

      <div className="container HeaderEnd">
        <div className="celd-bio2 shadow p-3 mb-5 bg-rgba(115, 214, 214, 0.342) rounded">
          <div className="bio">
            <p>
              I was born in Lima, Peru. Since childhood, I have always been
              interested in understanding how things function, and I would
              describe myself as an avid design improvements thinker. Technology
              is a passion of mine, in fact, I studied Computer and Systems
              Engineering at University San Martin de Porres in Lima. In 2005, I
              started working at IT companies. I was valued for my efficiency,
              in particular, I was recognized and awarded for achieving my sales
              goals. Marketing orientations led me to specialize in this field,
              which allowed me to use my creativity and innovation skills to
              achieve better results.{" "}
            </p>
            <p>
              In 2008, I assumed the challenge to lead a new IT company, a
              branch of a Chilean consortium specialized in Backup and
              Restoration of Information. We were only three people, but after
              all the handwork, we surpassed the established goals. Then, once
              the company was profitable, it was bought by an IT Latin-American
              company leader.
            </p>

            <p>
              In search of new incentives and advancement in my professional and
              personal life, in 2011, I decided to pursue a Master of Strategic
              Design Labs at Instituto Europeo di Design Madrid. More
              specifically, I had the opportunity to travel to Berlin,
              Copenhagen and Silicon Valley which helped me to expand my vision
              of the world in terms of culture, design, and innovation. This was
              one of my most creative periods as a result many projects were
              conceived and shaped by applying new tools and concepts.
            </p>

            <p>
              From 2012 to 2019, I have been working in recognized corporations:
              Licencias Online (LA licenses value added distributor), Logicalis
              (British company specialized in solutions in networks), IBM
              (working in service area GTS), and HPE (Aruba division).
              Currently, I have the knowledge to understand how the entire IT
              supply chain operates.
            </p>
          </div>
        </div>
      </div>
      <div className="in-my-mind">
        What is in my MIND{" "}
        <img
          src={require(`../src/images/brain3.png`)}
          className="brain"
          alt=""
        />
        ? :
      </div>
      <div className="row">
        <div className="col-6 col-md-3 todo robot">
          <img
            src={require(`../src/images/reals/robot-2.png`)}
            className="img images-fav"
            alt="robot"
          />
          <div className="text-over-img">Technology</div>
        </div>

        <div className="col-6 col-md-3 todo engranajes">
          <img
            src={require(`../src/images/reals/engranajes.png`)}
            className="images-fav"
            alt="tools"
          />
          <div className="text-over-img">Process Reengineering</div>
        </div>

        <div className="col-6 col-md-3 todo horse">
          <img
            src={require(`../src/images/reals/horse-chess.png`)}
            className="images-fav"
            alt="process"
          />
          <div className="text-over-img">Business Strategy development</div>
        </div>

        <div className="col-6 col-md-3 todo butterfly">
          <img
            src={require(`../src/images/reals/butterfly.png`)}
            className="images-fav"
            alt="butterfly"
          />
          <div className="text-over-img">Design of New Services</div>
        </div>

        <div className="col-6 col-md-3 todo led">
          <img
            src={require(`../src/images/reals/led.png`)}
            className="images-fav"
            alt="led"
          />
          <div className="text-over-img">Creative Thinking</div>
        </div>

        <div className="col-6 col-md-3 todo fav">
          <div className="float-text">
            my fav statement{" "}
            <span className="quote"> "everything adds something"</span>
          </div>
        </div>

        <div className="col-6 col-md-3 todo drum">
          <img
            src={require(`../src/images/reals/battery3.png`)}
            className="images-fav"
            alt="drums"
          />
          <div className="text-over-img"> Drums</div>
        </div>

        <div className="col-6 col-md-3 todo laptop">
          <img
            src={require(`../src/images/reals/coder-laptop.png`)}
            className="images-fav"
            alt="coding"
          />
          <div className="text-over-img"> Coding </div>
        </div>
      </div>
      <div className="my-path">
        My Path{" "}
        <img src={require(`../src/images/path.png`)} className="path" alt="" />{" "}
        in some pics:
      </div>
      <div className="container">
        <SimpleReactLightbox>
          <Photos />
        </SimpleReactLightbox>
        <Videos />
      </div>
    </div>
  );
}
