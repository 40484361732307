import React from "react";
import { Container, darkColors, Link } from "react-floating-action-button";

export default function Nav() {
  return (
    <Container>
      <Link
        href="http://weather.mariann.me"
        tooltip="Go to JavaScript Weather"
        className="btn fa-leaf"
        styles={{
          backgroundColor: darkColors.blue,
        }}
      />
      <Link
        href="http://snoopyweather.mariann.me"
        tooltip="Go to React Snoopy Weather"
        icon="fa-star"
        styles={{
          backgroundColor: darkColors.yellow,
        }}
      />
    </Container>
  );
}
