import React from "react";

export default function Videos() {
  return (
    <div>
      <div className="videos">
        My Videos{" "}
        <img
          src={require(`../src/images/video_icon2.jpg`)}
          className="path"
          alt="videoIcon"
        />{" "}
        :
      </div>

      <iframe
        src="https://player.vimeo.com/video/40445015"
        width="540"
        height="260"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        title="Popupgift"
      />
      <iframe
        src="https://player.vimeo.com/video/412996357"
        width="540"
        height="260"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        title="IBM"
      ></iframe>
    </div>
  );
}
