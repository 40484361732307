import React from "react";
import { SRLWrapper } from "simple-react-lightbox";

export default function Photos() {
  const options = {
    settings: {
      overlayColor: "rgb(25, 136, 124, 0.9)",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
      hideControlsAfter: 1000,
    },
    buttons: {
      backgroundColor: "#1b5245",
      iconColor: "rgba(126, 172, 139, 0.8)",
      showDownloadButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    caption: {
      captionColor: "#fffff",
      captionFontFamily: "Lato, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
  };
  return (
    <SRLWrapper options={options}>
      <div className="row ImagesRow no-gutters">
        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/shecodes.jpg")} data-attribute="SRL">
            <img
              src={require("./images/shecodesT.jpg")}
              alt="SheCodes FrontEnd Certification, Lisbon-Portugal"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/lisbon.jpg")} data-attribute="SRL">
            <img
              src={require("./images/lisbonT.jpg")}
              alt="Living in Lisbon-Portugal 2020"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/aruba.jpg")} data-attribute="SRL">
            <img
              src={require("./images/arubaT.jpg")}
              alt="HPE Aruba Onboarding, Santa Clara-CA"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/istambulove21.JPG")} data-attribute="SRL">
            <img
              src={require("./images/istambulove21T.jpg")}
              alt="Istanbulove 💜, Karakoy Istanbul-Turkey 2019"
            />
          </a>
        </div>
        {/* </div> */}

        {/* <div className="row ImagesRow no-gutters"> */}
        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/myfamily.jpg")} data-attribute="SRL">
            <img
              src={require("./images/myfamilyT.jpg")}
              alt="My eveything, Mel's Wedding 2019 "
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/evento_sonda.jpg")} data-attribute="SRL">
            <img
              src={require("./images/evento_sondaT.jpg")}
              alt="Evento Sonda - HPE Aruba, Lima-Peru 2019"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/sintra.jpg")} data-attribute="SRL">
            <img
              src={require("./images/sintraT.jpg")}
              alt="Sintra, Lisbon-Portugal"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/googlemar2.jpg")} data-attribute="SRL">
            <img
              src={require("./images/googlemar2T.jpg")}
              alt="Google, Mountain View -CA"
            />
          </a>
        </div>
        {/* </div> */}

        {/* <div className="row ImagesRow no-gutters"> */}
        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/IBM.jpg")} data-attribute="SRL">
            <img
              src={require("./images/IBMT.jpg")}
              alt="IBM Global Sales School, Lima-Peru 2018"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/shiraz.jpg")} data-attribute="SRL">
            <img
              className="picture"
              src={require("./images/shirazT.jpg")}
              alt="Shiraz 🐾"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/cisco_live.jpg")} data-attribute="SRL">
            <img
              src={require("./images/cisco_liveT.jpg")}
              alt="Cisco Live Cancun"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a
            href={require("./images/pacman_logicalis.jpg")}
            data-attribute="SRL"
          >
            <img
              src={require("./images/pacman_logicalisT.jpg")}
              alt="Halloween Pacman Logicalis"
            />
          </a>
          {/* </div> */}
        </div>

        {/* <div className="row ImagesRow no-gutters"> */}
        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/myfamily2.jpg")} data-attribute="SRL">
            <img
              src={require("./images/myfamily2T.jpg")}
              alt="My Awesome family"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/cambridge.jpg")} data-attribute="SRL">
            <img
              src={require("./images/cambridgeT.jpg")}
              alt="Studing in Cambridge, England 2012"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a href={require("./images/ied.jpg")} data-attribute="SRL">
            <img
              src={require("./images/iedT.jpg")}
              alt="Master Strategic IED Madrid"
            />
          </a>
        </div>

        <div className="col-6 col-sm-3 grid">
          <a
            href={require("./images/slepping kangaroo.jpg")}
            data-attribute="SRL"
          >
            <img
              src={require("./images/slepping kangarooT.jpg")}
              alt="Sleeping Kangaroo, Melbourne-Australia"
            />
          </a>
        </div>
      </div>
    </SRLWrapper>
  );
}
