import React from "react";
import Home from "./Home";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="Container">
        <Home />
        <footer className="container">
          <small>coded in React by Mariann Montoya Biondi</small>
        </footer>
      </div>
    </div>
  );
}

export default App;
